<template>
  <v-container class="mkt-content-container mt-5">
    <px-cadastro-comprador-header :show-description="false"/>
    <v-row class="ml-2 mt-5 text-center">
      <v-col cols="12">
        <p>
          <v-icon size="100" color="success">
            mdi-checkbox-marked-circle-outline
          </v-icon>
        </p>
        <p>Obrigado por criar seu cadastro em nosso marketplace.</p>
        <p>
          Caso tenha alguma dúvida ou precise de suporte, entre em contato através do e-mail
          <b> suporte@proxpect.com.br</b> ou via
          <a target="_blank"
             href="https://api.whatsapp.com/send?phone=554891075464&text=Ol%C3%A1!%20Preciso%20de%20ajuda%20com%20o%20Marketplace%20Proxpect!">
            Whatsapp
          </a>
          .
        </p>
        <p class="mt-10">
          <v-btn large
                 class="rounded-card-small normal-btn-text mr-3"
                 color="primary" link to="/login">
            Ir para o login
          </v-btn>
          <v-btn large class="rounded-card-small normal-btn-text" link to="/home">
            Continuar navegando
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CadastroCompradorHeader from './CadastroCompradorHeader.vue';

export default {
  components: {
    pxCadastroCompradorHeader: CadastroCompradorHeader,
  },
};
</script>
